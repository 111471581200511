import './App.less';
import { HashRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import SignIn from './container/singIn';
import Panel from './container/Panel';
import ForgotPassword from './container/ForgotPassword';
import ResetPassword from './container/ResetPassword';
import { useEffect } from 'react';
import { useAuth } from './hooks/authContext';
import Cookies from './tools/cookies';
import { TOKEN, USER_DATA } from './tools/constants';
import { getStorage } from './tools/storage';

// const socket = new WebSocket("ws://192.168.1.106:4001/ws");
const cookies = new Cookies();

function App() {
  const {setAuth, setToken} = useAuth();
  // const [token, addToken] = useState('');
  const navigate = useNavigate();
  // const location = useLocation();

  useEffect(() => {
    // console.log(location)
    const t = cookies.getCookie(TOKEN);
    if(t !== '') {
      setAuth(getStorage(USER_DATA));
      setToken(t)
      navigate("/app/orders#inprogress");
      console.log({t, data: getStorage(USER_DATA)})
    }
  },[])



  return (
    <Routes>
    {/* {token !== null} */}
    <Route path="/" element={ <Navigate to="/login" />} />
    <Route path="/login" element={<SignIn />} />
    <Route path="/app/*" element={<Panel />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route path='/reset-password/:email' element={<ResetPassword />} />
  </Routes>
  );
}

export default App;
