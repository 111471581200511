import { Button, Card, Checkbox, Col, Form, Input, message, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HeaderPanel from '../components/headerPanel';
import { useAuth } from '../hooks/authContext';
import { post } from '../tools/api';
import { TOKEN, USER_DATA } from '../tools/constants';
import Cookies from '../tools/cookies';
import { setStorage } from '../tools/storage';

const cookies = new Cookies();

const {Title, Text} = Typography;

const SignIn = () => {
  // const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const {setToken, setAuth} = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState('');

  useEffect(() => {
    let abortController = new AbortController();
    return () => {  
      abortController.abort();  
      } 
  },[]);

  const onFinish = async (values) => {
    //     socket.send(JSON.stringify({
    //   message: "holas desde react"
    // }))
    console.log(window.location);
    console.log(values);
    try {
      setLoading(true);
      const response = await post("/business/login", {...values});
      console.log(response);
      if (!response.success) {
        message.error(response.message)
      } else {
        console.log(response)
        delete response.data.password;
        cookies.setCookie(TOKEN, response.token, 30);
        setStorage(USER_DATA, response.data);
        setAuth(response.data);
        setToken(response.token);
        navigate("/app/orders#inprogress");
        // window.location.href = window.location.origin+"/app/orders#inprogress"
        // message.loading({content:"Redirigiendo...", onClose: () => navigate("/app/orders#inprogress"), duration: 1});
      }
      setLoading(false);
    } catch (error) {
      console.log(error)
      message.error("Error al conectarse al servidor")
      setLoading(false)
    }
  }

    return (
      <div className='signin_node'>
          <HeaderPanel />
        <div style={{paddingTop: '5%'}}>
            
          <Row justify='center'>
            <Col xs={20} sm={18} md={12} lg={9}>
                <Card bordered={false}>
                <Title>Iniciar Sesión</Title>
              <Form layout="vertical" name="form-signup" onFinish={onFinish}>
                <Form.Item rules={[
                  {
                    required: true,
                    message: "Debes llenar este campo"
                  }
                ]} name="email" label="Correo electrónico">
                  <Input type="email" className='custom_input' />
                </Form.Item>
                <Form.Item rules={[
                  {
                    required: true,
                    message: "Debes llenar este campo"
                  }
                ]} name="password" label="Contraseña">
                  <Input.Password className='custom_input' />
                </Form.Item>
                <Form.Item valuePropName="checked" name="remember_me">
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Checkbox>Recordar contraseña</Checkbox>
                    <Link to="/forgot-password"><Text underline>Olvidaste tu contraseña</Text></Link>
                </div>
                </Form.Item>
                <div style={{textAlign: 'center'}}>
                <Button style={{marginBottom: 20}} htmlType='submit' loading={loading} className='custom_button' type='primary'>Ingresar</Button>
                <Text style={{fontSize: 16, color: "#333"}}>¿Aún no tienes cuenta? <Link to="#">Registrate</Link></Text>
                </div>
              </Form>
                </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
}

export default SignIn;