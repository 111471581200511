import { message } from "antd";
import { useEffect, useState } from "react";
import { get, headerBearer } from "../tools/api";
import { USER_DATA } from "../tools/constants";
import { getStorage } from "../tools/storage";

export default function useNotification() {
    const [user, setUser] = useState(getStorage(USER_DATA));
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchData();
    },[]);

    const fetchData = async () => {
        try {
            const response = await get(`/notification?id=${user.id}&type=business`, headerBearer);
            if(!response.success) throw response;
            setData(response.data);
        } catch (error) {
            message.error(error.message);
            console.log(error);
        }
    }

    return {
        loading,
        fetchData,
        data
    }
}