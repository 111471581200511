import { Tabs } from "antd";
import React from "react";
import Banks from "./banks";
import MyData from "./mydata";
import useProfile from "./useProfile";

const { TabPane } = Tabs;
const ProfileContext = React.createContext();

function Profile() {
  const {form, formbank, loading, saveData, onFinish} = useProfile()
  return (
    <ProfileContext.Provider value={{
      form,
      formbank,
      saveData,
      onFinish,
      loading
    }}>
      <div style={{ padding: "0 0" }}>
        {/* <HeaderSection title="Configuración" /> */}
          <Tabs tabPosition="left">
          <TabPane tab="Mis datos" key="1">
            <MyData />
          </TabPane>
          <TabPane tab="Bancos" key="2">
            <Banks />
          </TabPane>
        </Tabs>
      </div>
    </ProfileContext.Provider>
  );
}

export default Profile;
export {ProfileContext};