import { Button, Col, Row, Typography } from 'antd';
import moment from 'moment';
import React, { createContext } from 'react';
import { EditMyInfoIcon } from '../../components/customIcon';
import ModalEdit from './ModalEdit';
import useCoverage from './useCoverage';
const format = 'h:mm A';

const {Title, Text} = Typography;
const CoverageContex = createContext()
const Coverage = () => {
    const {
        user,
        visible,
        openModal,
        closeModal,
        form,
        onFinish,
        loading,
        closeBusiness
    } = useCoverage();
    return (
        <CoverageContex.Provider value={{
            visible,
            closeModal,
            form,
            onFinish,
            loading
        }}>
        <div>
            <Row>
                <Col span={24}>
                    <Title level={3}>Cobertura</Title>
                </Col>
                <Col span={24}>
                    <div className='coverage_card'>
                        <div className='coverage_item'>
                            <Text>Distancia máxima de cobertura que tendrá tu local</Text>
                            <Text><EditMyInfoIcon onClick={openModal} /> {user.distanceMax}km</Text>
                        </div>
                        <div style={{marginTop: 10}} className='coverage_item'>
                            <Text>Distancia predeterminada para el delivery gratis</Text>
                            <Text><EditMyInfoIcon onClick={openModal} /> {user.freeDistance}km</Text>
                        </div>
                    </div>
                </Col>
                <Col span={24} style={{marginTop: 20}}>
                    <Title level={3}>Horarios de atención</Title>
                </Col>
                <Col span={24}>
                    <div className='coverage_card'>
                        <Title level={4}>Lunes - Viernes</Title>
                        <Row>
                            <Col span={12}>
                                <Text className='coverage_subtitle'><EditMyInfoIcon onClick={openModal} /> Abre: {moment(user.openLV === '' ? 0 : user.openLV).format(format)}</Text>
                            </Col>
                            <Col span={12}>
                                <Text className='coverage_subtitle'><EditMyInfoIcon onClick={openModal} /> Cierra: {moment(user.closeLV === '' ? 0 : user.closeLV).format(format)}</Text>
                            </Col>
                        </Row>
                        <Title style={{marginTop: 20}} level={4}>Sábado - Domingo</Title>
                        <Row>
                            <Col span={12}>
                                <Text className='coverage_subtitle'><EditMyInfoIcon onClick={openModal} /> Abre: {moment(user.openSD === '' ? 0 : user.openSD).format(format)}</Text>
                            </Col>
                            <Col span={12}>
                                <Text className='coverage_subtitle'><EditMyInfoIcon onClick={openModal} /> Cierra: {moment(user.closeSD === '' ? 0 : user.closeSD).format(format)}</Text>
                            </Col>
                        </Row>
                        <Button onClick={closeBusiness} loading={loading} className='custom_button' style={{maxWidth: '30%', minWidth: '20%', marginTop: 20}} type={!user.open ? 'primary' : 'default'}>{!user.open ? 'Abrir negocio' : 'Cerrar negocio'}</Button>
                    </div>
                </Col>
            </Row>
        </div>
        {/* <Modal /> */}
        <ModalEdit />
        </CoverageContex.Provider>
    );
}

export default Coverage;
export {CoverageContex}