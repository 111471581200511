import React, { Suspense, useState } from 'react';
import { Layout, Menu, Spin } from 'antd';

import {PowerOffIcon} from '../components/customIcon'
import HeaderPanel from '../components/headerPanel';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
// import poweroff from '../assets/power.circle.fill.svg';
import { routesMap } from '../tools/routerMap';
import { useAuth } from '../hooks/authContext';
import { deleteStorage } from '../tools/storage';
import { TOKEN, USER_DATA } from '../tools/constants';
import Cookies from '../tools/cookies';
import Profile from '../routes/profile';
import Notifications from './Notification';
import Products from '../routes/myshop/products';
import arrowSide from '../assets/images/arrowSide.svg'

const cookies = new Cookies();
const { Content, Sider } = Layout;

const Panel = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // const set
    const {token, setToken, setAuth} = useAuth();

    const [collapsed, setCollapsed] = useState(false);
    const [visible, setVisible] = useState(false);
    
    // useEffect(() => {
    //   setAuth(getStorage(USER_DATA))
    // },[])

    // return auth === null && 
    if(token === null) {
      navigate("/");
    } 

    const logout = () => {
      deleteStorage(USER_DATA);
      cookies.deleteCookie(TOKEN)
      setToken(null);
      setAuth(null);
      navigate("/");
    }

    const onCollapse = () => {
        setCollapsed(!collapsed);
      };

    return (
        <Layout className='eden_panel'>
            <HeaderPanel showbuttons={true}></HeaderPanel>
            <Layout style={{paddingTop: 10}}>
            <Sider className="eden_sidebar" width={280} trigger={null} collapsible collapsed={collapsed}>
            <div className="container_menu_group">
              <div className="close_side_container">
                <img className={collapsed ? "button_close" : null} onClick={onCollapse} src={arrowSide} alt="arrowBack" />
              </div>
              <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname+location.hash]}>
                {routesMap.map((item) => (
                  <Menu.Item key={item.path} icon={<item.icon />}>
                    <Link className="link_sidebar" to={`/app${item.path}`}>
                      {item.name}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu>
            </div>

            <Menu theme="dark" mode="inline">
              <Menu.Item onClick={logout} className="closed_sesion" key="closed_sesion" icon={<PowerOffIcon />}>
                Cerrar Sesión
              </Menu.Item>
            </Menu>
          </Sider>
        <Content
            className="site-layout-background"
            style={{
              overflowY: "auto",
              margin: '24px 16px',
              padding: 24,
              minHeight: 280,
            }}
          >
            <Suspense fallback={<Spin />}>
              <Routes>
                  {routesMap.map(item => <Route key={item.path} path={`${item.path}`} element={<item.View />} />)}
                  <Route key="/profile" path="/profile" element={<Profile />} />
                  <Route key="/notifications" path="/notifications" element={<Notifications />} />
                  <Route key="/myshop/products" path="/myshop/products" element={<Products />} />
                </Routes>
            </Suspense>
          </Content>
            </Layout>
      </Layout>
    );
}

export default Panel;