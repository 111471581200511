import { Button, Col, Form, Input, Radio, Row, Select, Typography } from 'antd';
import React, { useContext, useState } from 'react';
import { ProfileContext } from '.';
import { banksMap, TypeAccoutnBankMap, TypeDocument } from './jsonbank';

const {Title} = Typography;
const {Option} = Select;

const Banks = () => {
    const {formbank, onFinish, loading} = useContext(ProfileContext);
  const [selects, setSelects] = useState({bank: banksMap[0], typeBank: TypeAccoutnBankMap[0], typeDoc: TypeDocument[0]});


    return (
      <div style={{ paddingBottom: 20 }}>
        <div className="add_bank">
          <Title level={3}>Información bancaria</Title>
        </div>
        <Form form={formbank} layout="vertical" onFinish={onFinish} name="banks">
            <Row>
              {/* nombre del titular */}
              <Col className="ph-1" xs={24} sm={24} md={24} lg={12}>
                <Form.Item name="name_owner" label="Nombre de titular">
                  <Input className="custom_input" />
                </Form.Item>
              </Col>
              {/* nombre del banco */}
              <Col className="ph-1" xs={24} sm={24} md={24} lg={12}>
                <Form.Item name="name_bank" label="Nombre del banco">
                  <Select
                    onChange={(e) => setSelects({...selects, bank: e})}
                    className="custom_select"
                  >
                    {/* <Select.Option className="option_" value={1}>1 <Radio checked={category === 1} /></Select.Option> */}
                    {banksMap.map((item) => (
                      <Option key={item} value={item}>
                        {item} <Radio checked={selects.bank === item} />
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* numero de cuenta */}
              <Col className="ph-1" xs={24} sm={24} md={24} lg={12}>
                <Form.Item name="number_account" label="Numero de cuenta">
                  <Input className="custom_input" />
                </Form.Item>
              </Col>
              {/* tipo de cuenta */}
              <Col className="ph-1" xs={24} sm={24} md={24} lg={12}>
                <Form.Item name="type_account" label="Tipo de cuenta">
                <Select
                    onChange={(e) => setSelects({...selects, typeBank: e})}
                    className="custom_select"
                  >
                    {/* <Select.Option className="option_" value={1}>1 <Radio checked={category === 1} /></Select.Option> */}
                    {TypeAccoutnBankMap.map((item) => (
                      <Option key={item} value={item}>
                        {item} <Radio checked={selects.typeBank === item} />
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* Telefono */}
              <Col className="ph-1" xs={24} sm={24} md={24} lg={12}>
                <Form.Item name="type_document" label="Documento de indetificación">
                <Select
                    onChange={(e) => setSelects({...selects, typeDoc: e})}
                    className="custom_select"
                  >
                    {/* <Select.Option className="option_" value={1}>1 <Radio checked={category === 1} /></Select.Option> */}
                    {TypeDocument.map((item) => (
                      <Option key={item} value={item}>
                        {item} <Radio checked={selects.typeDoc === item} />
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/* documento de identificacion */}
              <Col className="ph-1" xs={24} sm={24} md={24} lg={12}>
                <Form.Item name="number_document" label="Número de Identificación">
                  <Input className="custom_input" />
                </Form.Item>
              </Col>
              <Col span={24}>
              <div className="footer_card_modal">
            <Button htmlType='submit' className='custom_button_form' loading={loading} type="primary">Guardar</Button>
          </div>
              </Col>
            </Row>
          </Form>
      </div>
    );
}

export default Banks;