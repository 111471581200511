import { Button, Col, Row, Typography } from 'antd';
import React from 'react';

import { useNavigate } from 'react-router-dom';
import AvatarMyShop from './components/AvatarMyShop';
import BannerPrimary from './components/BannerPrimary';
import EditTextMyShop from './components/EditText';
import GoogleMap from './components/GoogleMap';
import PromotionBanner from './components/PromotionBanner';

const {Title, Text} = Typography;
const MyShop = () => {
    const navigate = useNavigate();

    return (
        <div>
            <Row>
                <Col span={12}>
                <Title level={3}>Mi Tienda</Title>
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                    <Button onClick={() => navigate("/app/myshop/products")} type='primary' style={{maxWidth: "60%"}} className='custom_button'>Administrar productos</Button>
                </Col>
                <Col span={24}>
                    <BannerPrimary />
                </Col>
                <Col span={8}>
                    <AvatarMyShop />
                </Col>
                <Col span={16} style={{paddingLeft: 20}}>
                    <EditTextMyShop placeholder="Ejm: El rincón saludable" field="nameBusiness" title="Nombre del Negocio" />
                </Col>
                <Col span={24}>
                    <EditTextMyShop placeholder="Ejm: Articulos de salud" field="description" title="Descripción del Negocio" />
                </Col>
                <Col span={24}>
                    <GoogleMap />
                </Col>
                <Col span={24}>
                    <Title style={{marginTop: 20}} level={4}>¡Promocionate!</Title>
                    <Text className='text_span'>Coloca banner llamativo y comienza hacerte conocido en Mandaitos</Text>
                </Col>
                <Col span={24}>
                    <PromotionBanner />
                </Col>
                <Col style={{paddingTop: 20}} span={24}>
                <Text  className='text_span'>¡No esperes más y empieza a personalizar tu tienda!</Text><br />
                <Button type='primary' style={{maxWidth: "30%"}} className='custom_button'>Administrar productos</Button>
                </Col>
            </Row>
            
        </div>
    );
}

export default React.memo(MyShop);