import { BellFilled } from '@ant-design/icons';
import { Avatar, Badge, Modal, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from "../assets/logo.svg";
import { useAuth } from '../hooks/authContext';
import { HeadPhoneIcon } from './customIcon';

const {Title, Text} = Typography;
function ModalSupport({onCancel, visible}) {
  const {auth} = useAuth();
  return(<Modal footer={null} onCancel={onCancel} visible={visible}>
    <Title className='title_suppormodal' level={3}>Ayuda y Soporte</Title>
    <Text className='text_supportmodal' strong>¡Estamos para ayudarte!</Text>
    <Text className='text_supportmodal'>Si tienes algún problema o duda comunicate con nosotros.</Text>
    <div className='card_suppor_modal'>
    <Text className='text_supportmodal' strong>Nombre de administrador</Text>
    <Text className='text_supportmodal'>Mandaitos</Text>
    </div>
    <div className='card_suppor_modal'>
    <Text className='text_supportmodal' strong>Teléfono</Text>
    <Text className='text_supportmodal'>349 214 35</Text>
    </div>
    <div className='card_suppor_modal'>
    <Text className='text_supportmodal' strong>Correo electrónico</Text>
    <Text className='text_supportmodal'>ayuda_y_soporte.com</Text>
    </div>

    {auth !== null && auth.status === 0 && <Text type='danger' strong>Cuenta no está activada. Termine de llenar los datos faltantes y notifique al administrador</Text>}
  </Modal>)
};


const HeaderPanel = ({showbuttons}) => {
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  

    useEffect(() => {
      console.log(location)
    }, []);

    return (
      <div className="eden_header_bar">
        <img alt="eden_logo" width={60} src={logo} />
        {showbuttons ? <Space>
          <Link to="/app/profile"><Avatar style={{backgroundColor: location.pathname === "/app/profile" ? "#FA8B20" : "#668998"}} size={40} >LC</Avatar></Link>
          <Link to="/app/notifications">
          <Badge dot>
          <Avatar style={{backgroundColor: location.pathname === "/app/notifications" ? "#FA8B20" : "#668998"}} size={40} icon={<BellFilled />} />
      </Badge>
      </Link>
      <div onClick={() => setVisible(!visible)}>
      <Avatar style={{cursor: 'pointer', backgroundColor: visible ? "#FA8B20" : "#668998"}} size={40} icon={<HeadPhoneIcon />} />
      </div>
      
        </Space> : null}
        <ModalSupport onCancel={() => setVisible(false)} visible={visible} />
      </div>
    );
}

export default HeaderPanel;