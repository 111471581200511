import { Button, Col, Row, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { CircleAddIcon } from '../../components/customIcon';
import { get } from '../../tools/api';
import { updateArray } from '../../tools/arrayTool';
import { USER_DATA } from '../../tools/constants';
import { getStorage } from '../../tools/storage';
import ProductCard from './components/ProductCard';
import ModalOffert from './modalAddOffert';
import ModalProduct from './modalProduct';
import useProduct from './useProducts';

const {Title, Text} = Typography;
const ProductContext = React.createContext();


const Products = () => {
    const {visible, voffert, record, toggleModalOffert, onSave, data, toggleModal, image, onFinish, categories, loading, selectedImage, subcategories, getSubcategories, setImage, form, fetchData} = useProduct();

    return (
        
        <ProductContext.Provider value={{
            visible,
            record,
            onSave,
            toggleModal,
            voffert,
            toggleModalOffert,
            image,
            selectedImage,
            setImage,
            loading,
            onFinish,
            categories,
            subcategories,
            getSubcategories,
            form
        }}>
            <Title level={3}>Administra tus productos</Title>
            <Row>
                <Col span={12}>
                <Title level={5}>Productos</Title>
                </Col>
                <Col span={12} style={{textAlign: 'right'}}>
                <Text>{`${data.rows.length} de ${data.total_rows}`}</Text>
                </Col>
                <Col xs={24} sm={12} md={8} lg={4}>
                <div onClick={toggleModal} className='upload_product_empty'>
                        <CircleAddIcon />
                        <Text style={{paddingLeft: 20}}>Agregar producto</Text>
                    </div>
                </Col>
                {data.rows.map(item => 
                <ProductCard key={item.id} onClick={() => toggleModal(item)} data={item} />)}
                {data.page === data.total_pages && <Col span={24} style={{textAlign: 'center', marginTop: 10}}><Button onClick={data.page < data.total_pages ? () => fetchData(data.page+1) : () => {}} loading={loading} type='primary'>Ver más</Button></Col>}
            </Row>
            {/* <Row style={{marginTop: 30}}>
                <Col span={24}>
                <Title level={5}>Ofertas</Title>
                </Col>
                <Col xs={24} sm={12} md={8} lg={4}>
                <div onClick={toggleModalOffert} className='upload_product_empty'>
                        <CircleAddIcon />
                        <Text style={{paddingLeft: 20}}>Agregar Oferta</Text>
                    </div>
                </Col>
            </Row> */}
            <ModalProduct />
            <ModalOffert />
        </ProductContext.Provider>
    );
}

export default Products;
export {ProductContext}