import {Button, Col, Form, Input, InputNumber, Modal, Row, TimePicker, Typography} from 'antd';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { CoverageContex } from '.';

const {Title} = Typography;
const format = 'h:mm a';

function ModalEdit() {
    const {
        visible,
        closeModal,
        form,
        onFinish,
        loading
    } = useContext(CoverageContex);

    // useEffect(() => {},[visible])
    return(
        <Modal
        visible={visible}
        onCancel={closeModal}
        footer={[
            <Button onClick={() => {
                // form.resetFields()
                // setImage(null);
                closeModal()
            }} className='custom_button_form_cancel' type='link' danger key="back">
            Cancelar
          </Button>,
        <Button loading={loading} onClick={onFinish} className='custom_button_form' type='primary' key="create">
        Guardar
    </Button>]}>
            <Title level={3}>Cobertura</Title>
            <Form form={form} layout='vertical'>
                <Row>
                    <Col md={24} lg={12} style={{padding: "0 8px"}}>
                        <Form.Item name="distance_max" label="Distancia máxima">
                            <InputNumber className='eden_input' />
                        </Form.Item>
                    </Col>
                    <Col md={24} lg={12}>
                        <Form.Item name="free_distance" label="Distancia gratis">
                            <InputNumber className='eden_input' />
                        </Form.Item>
                    </Col>
                </Row>
                <Title level={3}>Horario</Title>
                <Row>
                    <Col span={24} style={{padding: "0 8px"}}>
                        <Form.Item name="lv" label="Lunes a viernes">
                        <TimePicker.RangePicker 
                        use12Hours className='coverage_time' format={format} placeholder={["Hora apertura", "Hora cierre"]} />
                        </Form.Item>
                    </Col>
                    <Col span={24} style={{padding: "0 8px"}}>
                        <Form.Item name="sd" label="Sabado y domingo" placeholder={["Hora apertura", "Hora cierre"]}>
                        <TimePicker.RangePicker 
                        use12Hours className='coverage_time' format={format} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}

export default React.memo(ModalEdit);