import React from 'react';
import Icon from '@ant-design/icons';
const ArrowSidebar = () => (
    <svg width="25" height="20" viewBox="0 0 30 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.34325 22.4744V12.4769C4.42172 12.8378 4.61006 13.1517 4.86118 13.4185L14.7489 23.4004C15.1255 23.7771 15.565 23.9654 16.0201 23.9654C17.0246 23.9654 17.7466 23.2592 17.7466 22.2861C17.7466 21.7839 17.5425 21.3601 17.2129 21.0305L14.0583 17.8288L9.50682 13.7011L12.8184 13.858H27.3204C28.3719 13.858 29.0939 13.1517 29.0939 12.1159C29.0939 11.08 28.3719 10.3581 27.3204 10.3581H12.8184L9.52252 10.515L14.0583 6.40299L17.2129 3.18556C17.5268 2.87167 17.7466 2.43222 17.7466 1.92998C17.7466 0.972604 17.0246 0.250645 16.0201 0.250645C15.565 0.250645 15.1255 0.438982 14.7489 0.815656L4.86118 10.8132C4.61006 11.08 4.42172 11.3939 4.34325 11.7392V1.77304C4.34325 0.799962 3.6056 0.0780029 2.61683 0.0780029C1.64375 0.0780029 0.906097 0.799962 0.906097 1.77304V22.4744C0.906097 23.4475 1.64375 24.1538 2.61683 24.1538C3.6056 24.1538 4.34325 23.4475 4.34325 22.4744Z" fill="currentColor"/>
    </svg>
);

const PowerOff = () => (
    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 26.4762C20.094 26.4762 25.9701 20.6126 25.9701 13.506C25.9701 6.41201 20.094 0.535889 12.9874 0.535889C5.89341 0.535889 0.0298462 6.41201 0.0298462 13.506C0.0298462 20.6126 5.90596 26.4762 13 26.4762ZM13.0125 13.8702C12.435 13.8702 12.0332 13.4558 12.0332 12.8657V7.3788C12.0332 6.78868 12.435 6.37434 13.0125 6.37434C13.5901 6.37434 13.9919 6.78868 13.9919 7.3788V12.8657C13.9919 13.4558 13.5901 13.8702 13.0125 13.8702ZM13.0125 20.2736C9.30859 20.2736 6.24497 17.1974 6.24497 13.506C6.24497 11.6729 7.02343 9.86485 8.3669 8.63438C9.3337 7.68014 10.7023 9.03617 9.72293 10.0155C8.75613 10.9321 8.21623 12.1626 8.21623 13.506C8.21623 16.1804 10.3507 18.3149 13.0125 18.3149C15.6744 18.3149 17.8089 16.1804 17.8089 13.506C17.8089 12.1626 17.2564 10.9321 16.3022 10.0281C15.3228 9.04872 16.6788 7.6927 17.6582 8.63438C19.0017 9.87741 19.7801 11.6854 19.7801 13.506C19.7801 17.1974 16.7165 20.2736 13.0125 20.2736Z" fill="#EB5757"/>
</svg>);

const CategoriesSvg = () => (
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.56976 15.7421C4.56976 17.5337 5.5031 18.4587 7.31143 18.4587H7.71976V11.1921C7.71976 8.71706 9.09476 7.35039 11.5864 7.35039H22.2698V7.24206C22.2698 5.45872 21.3281 4.52539 19.5281 4.52539H7.31143C5.5031 4.52539 4.56976 5.45872 4.56976 7.24206V15.7421ZM11.6864 22.4337H23.8198C25.6198 22.4337 26.5698 21.5004 26.5698 19.7171V11.2921C26.5698 9.50872 25.6198 8.57539 23.8198 8.57539H11.6864C9.8781 8.57539 8.94476 9.50039 8.94476 11.2921V19.7171C8.94476 21.5004 9.8781 22.4337 11.6864 22.4337Z" fill="currentColor"/>
</svg>);

const UserSvg = () => (
<svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0118 10.3905C12.5917 10.3905 14.7811 8.09468 14.7811 5.12426C14.7811 2.22485 12.5799 0 10.0118 0C7.43195 0 5.21894 2.26036 5.23077 5.14793C5.23077 8.09468 7.42012 10.3905 10.0118 10.3905ZM2.62722 21.3965H17.3728C19.3254 21.3965 20 20.8047 20 19.716C20 16.6746 16.142 12.4852 10 12.4852C3.86982 12.4852 0 16.6746 0 19.716C0 20.8047 0.674556 21.3965 2.62722 21.3965Z" fill="currentColor"/>
</svg>);

    const DocSvg = () => (
<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.13679 21.8741H14.6851C16.8126 21.8741 17.9109 20.7559 17.9109 18.6186V9.76235H10.4895C9.25263 9.76235 8.65891 9.16864 8.65891 7.93173V0.431152H4.13679C2.01922 0.431152 0.91095 1.54931 0.91095 3.69658V18.6186C0.91095 20.7559 2.01922 21.8741 4.13679 21.8741ZM10.6775 8.31765H17.812C17.7526 7.86247 17.4261 7.43697 16.9115 6.91253L11.4988 1.43057C11.0041 0.925914 10.5588 0.589476 10.1036 0.530105V7.74373C10.1135 8.12964 10.3015 8.31765 10.6775 8.31765ZM5.5716 14.1459C5.14611 14.1459 4.83935 13.8392 4.83935 13.4434C4.83935 13.0476 5.14611 12.7408 5.5716 12.7408H13.28C13.6857 12.7408 14.0023 13.0476 14.0023 13.4434C14.0023 13.8392 13.6857 14.1459 13.28 14.1459H5.5716ZM5.5716 17.8566C5.14611 17.8566 4.83935 17.5499 4.83935 17.1541C4.83935 16.7583 5.14611 16.4515 5.5716 16.4515H13.28C13.6857 16.4515 14.0023 16.7583 14.0023 17.1541C14.0023 17.5499 13.6857 17.8566 13.28 17.8566H5.5716Z" fill="currentColor"/>
</svg>);

const SquareSvg = () => (
<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.9679 3.94896L24.9096 2.95705C25.3867 2.45482 25.4244 1.76425 24.9473 1.28713L24.6208 0.948123C24.1939 0.521226 23.4782 0.584005 23.0137 1.04857L22.0343 2.00281L23.9679 3.94896ZM9.10183 17.3083L11.6758 16.2285L23.0513 4.85298L21.1177 2.93194L9.74218 14.3201L8.61216 16.8061C8.4866 17.0949 8.81305 17.4213 9.10183 17.3083ZM4.33063 25.7333H18.9581C21.3689 25.7333 22.7877 24.327 22.7877 21.6401V8.38116L20.2765 10.8798V21.4141C20.2765 22.6194 19.6362 23.2221 18.7949 23.2221H4.49385C3.33872 23.2221 2.71093 22.6194 2.71093 21.4141V7.46459C2.71093 6.25923 3.33872 5.65655 4.49385 5.65655H15.1537L17.6523 3.14539H4.33063C1.61857 3.14539 0.199768 4.55164 0.199768 7.23858V21.6401C0.199768 24.327 1.61857 25.7333 4.33063 25.7333Z" fill="#4F4F4F"/>
</svg>);

const BookSvg = () => (
<svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.79416 23.1211H17.4172C17.9722 23.1211 18.4015 22.6917 18.4015 22.1368C18.4015 21.6656 18.0769 21.32 17.679 21.1315C16.391 20.5556 16.1397 18.7755 17.4486 17.5294C17.9826 17.0582 18.391 16.5347 18.391 15.4771V3.88546C18.391 1.62368 17.2287 0.44043 14.9774 0.44043H3.8151C1.57426 0.44043 0.401489 1.6132 0.401489 3.88546V19.7074C0.401489 21.9483 1.57426 23.1211 3.79416 23.1211ZM3.65803 16.2205C3.31248 16.2205 3.01929 15.9378 3.01929 15.5818V2.83834C3.01929 2.48231 3.31248 2.19959 3.65803 2.19959C4.00358 2.19959 4.29678 2.48231 4.29678 2.83834V15.5818C4.29678 15.9378 4.00358 16.2205 3.65803 16.2205ZM7.21824 7.29907C6.7994 7.29907 6.4329 6.94305 6.4329 6.5242C6.4329 6.08441 6.7994 5.73886 7.21824 5.73886H14.7994C15.2287 5.73886 15.5847 6.08441 15.5847 6.5242C15.5847 6.94305 15.2287 7.29907 14.7994 7.29907H7.21824ZM7.21824 10.3357C6.7994 10.3357 6.4329 9.99017 6.4329 9.55038C6.4329 9.13153 6.7994 8.77551 7.21824 8.77551H12.9774C13.4067 8.77551 13.7627 9.13153 13.7627 9.55038C13.7627 9.99017 13.4067 10.3357 12.9774 10.3357H7.21824ZM4.07688 21.1525C2.95646 21.1525 2.37008 20.5137 2.37008 19.5294C2.37008 18.5766 3.04023 17.9169 4.15018 17.9169H14.6004C14.7156 17.9169 14.8308 17.9064 14.9355 17.8855C14.412 18.9954 14.4748 20.1996 15.0402 21.1525H4.07688Z" fill="#EB5757"/>
</svg>);

const CircleCloseSvg = () => (
<svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.9844 32.611C25.8833 32.611 33.2284 25.2659 33.2284 16.3827C33.2284 7.49944 25.8676 0.154297 16.9687 0.154297C8.08543 0.154297 0.755981 7.49944 0.755981 16.3827C0.755981 25.2659 8.10113 32.611 16.9844 32.611ZM11.6324 23.2256C10.8006 23.2256 10.1414 22.5664 10.1414 21.7189C10.1414 21.3422 10.2984 20.9655 10.5966 20.683L14.8656 16.3984L10.5966 12.1294C10.2984 11.8469 10.1414 11.4702 10.1414 11.0935C10.1414 10.246 10.8006 9.60254 11.6324 9.60254C12.0719 9.60254 12.4172 9.74379 12.6997 10.0263L16.9844 14.2953L21.2847 10.0106C21.5986 9.7124 21.9282 9.57115 22.352 9.57115C23.1838 9.57115 23.843 10.2303 23.843 11.0622C23.843 11.4545 23.686 11.7998 23.3878 12.1137L19.1188 16.3984L23.3878 20.6673C23.6703 20.9655 23.8273 21.3265 23.8273 21.7189C23.8273 22.5664 23.1681 23.2256 22.3206 23.2256C21.8968 23.2256 21.5358 23.0686 21.2376 22.7861L16.9844 18.5329L12.7311 22.7861C12.4486 23.0843 12.0719 23.2256 11.6324 23.2256Z" fill="#EB5757"/>
</svg>);

const BagSvg = () => (
<svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.57587 20.4434H14.6501C16.4675 20.4434 17.5222 19.3887 17.5222 17.3735V7.62709C17.5222 5.61189 16.4581 4.5572 14.4241 4.5572H13.0963C13.0492 2.44782 11.2506 0.696289 8.99997 0.696289C6.75876 0.696289 4.95073 2.44782 4.90364 4.5572H3.57587C1.54183 4.5572 0.477722 5.61189 0.477722 7.62709V17.3735C0.477722 19.3887 1.54183 20.4434 3.57587 20.4434ZM8.99997 2.36307C10.2901 2.36307 11.2412 3.33301 11.2789 4.5572H6.7211C6.75876 3.33301 7.70987 2.36307 8.99997 2.36307Z" fill="#828282"/>
</svg>);

    const CrediCardSvg = () => (
<svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.042 0.484375H3.94861C1.91457 0.484375 0.850464 1.53906 0.850464 3.55427V4.40179H23.1402V3.55427C23.1402 1.53906 22.0855 0.484375 20.042 0.484375ZM4.78671 13.7904C4.23111 13.7904 3.85444 13.4231 3.85444 12.8864V11.1443C3.85444 10.6169 4.23111 10.2402 4.78671 10.2402H7.09384C7.64001 10.2402 8.01669 10.6169 8.01669 11.1443V12.8864C8.01669 13.4231 7.64001 13.7904 7.09384 13.7904H4.78671ZM3.94861 16.8038H20.042C22.0855 16.8038 23.1402 15.7397 23.1402 13.7245V6.61475H0.850464V13.7245C0.850464 15.7491 1.91457 16.8038 3.94861 16.8038Z" fill="currentColor"/>
</svg>);

        const SaveSvg = () => (
<svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.94857 13.5509H20.0608C22.1043 13.5509 23.1589 12.5056 23.1589 10.5845V7.14739C23.1589 6.14921 23.1495 5.43352 22.5939 4.95326L19.2133 1.92104C18.1586 0.979353 17.3958 0.65918 15.9268 0.65918H8.08256C6.60412 0.65918 5.85077 0.979353 4.78666 1.92104L1.41543 4.95326C0.859837 5.43352 0.841003 6.14921 0.841003 7.14739V10.5845C0.841003 12.5056 1.90511 13.5509 3.94857 13.5509ZM12 9.20027C10.691 9.20027 9.87177 8.07024 9.87177 7.01556V6.98731C9.87177 6.60121 9.63634 6.24337 9.11842 6.24337H3.3553C2.86563 6.24337 2.69612 6.01737 3.10105 5.65011L6.17094 2.89097C6.68887 2.41071 7.04671 2.22238 7.85656 2.22238H16.1528C16.9627 2.22238 17.3111 2.41071 17.829 2.89097L20.9083 5.6407C21.3227 6.02679 21.1343 6.24337 20.6541 6.24337H14.8815C14.3636 6.24337 14.1282 6.60121 14.1282 6.98731V7.01556C14.1282 8.07024 13.3183 9.20027 12 9.20027ZM3.94857 22.3933H20.0608C22.0854 22.3933 23.1589 21.3386 23.1589 19.3234V16.0369C23.1589 15.4531 22.8105 14.9634 22.2173 14.9634H14.9004C14.3824 14.9634 14.147 15.3212 14.147 15.7073V15.7356C14.147 16.7997 13.3278 17.9297 12 17.9297C10.6722 17.9297 9.85293 16.7997 9.85293 15.7356V15.7073C9.85293 15.3212 9.61751 14.9634 9.09958 14.9634H1.81094C1.21768 14.9634 0.841003 15.4531 0.841003 16.0369V19.3234C0.841003 21.348 1.91452 22.3933 3.94857 22.3933Z" fill="currentColor"/>
</svg>);

const CircleAddSvg = () => (
<svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.6482 18.7422C16.0011 18.7422 20.4194 14.6289 20.4194 9.6543C20.4194 4.67969 15.9917 0.566406 10.6388 0.566406C5.2953 0.566406 0.886475 4.67969 0.886475 9.6543C0.886475 14.6289 5.30474 18.7422 10.6482 18.7422ZM6.07888 9.66309C6.07888 9.14453 6.47539 8.78418 7.02296 8.78418H9.71357V6.2793C9.71357 5.76953 10.0912 5.40918 10.6293 5.40918C11.1863 5.40918 11.5734 5.76953 11.5734 6.2793V8.78418H14.2734C14.8116 8.78418 15.2081 9.14453 15.2081 9.66309C15.2081 10.1641 14.8116 10.5244 14.2734 10.5244H11.5734V13.0293C11.5734 13.5303 11.1863 13.8994 10.6293 13.8994C10.0912 13.8994 9.71357 13.5303 9.71357 13.0293V10.5244H7.02296C6.47539 10.5244 6.07888 10.1641 6.07888 9.66309Z" fill="#333333"/>
</svg>
);

const EditMyInfoSvg = () => (
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.6377 2.52619L17.2969 1.83185C17.6309 1.48029 17.6572 0.996889 17.3232 0.662905L17.0947 0.4256C16.7959 0.126772 16.2949 0.170718 15.9697 0.495913L15.2842 1.16388L16.6377 2.52619ZM6.23145 11.8777L8.0332 11.1219L15.9961 3.159L14.6426 1.81427L6.67969 9.78595L5.88867 11.5262C5.80078 11.7283 6.0293 11.9569 6.23145 11.8777ZM2.8916 17.7752H13.1309C14.8184 17.7752 15.8115 16.7908 15.8115 14.91V5.62873L14.0537 7.37775V14.7518C14.0537 15.5955 13.6055 16.0174 13.0166 16.0174H3.00586C2.19727 16.0174 1.75781 15.5955 1.75781 14.7518V4.98712C1.75781 4.14337 2.19727 3.7215 3.00586 3.7215H10.4678L12.2168 1.96369H2.8916C0.993164 1.96369 0 2.94806 0 4.82892V14.91C0 16.7908 0.993164 17.7752 2.8916 17.7752Z" fill="currentColor"/>
</svg>);

const CoberturaSvg = () =>(
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.99997 17.9475C10.4143 17.9475 10.9887 16.3749 10.9887 13.8324V7.89976C12.5802 7.46659 13.7385 6.00698 13.7385 4.28369C13.7385 2.21198 12.0811 0.526367 9.99997 0.526367C7.92827 0.526367 6.2709 2.21198 6.2709 4.28369C6.2709 5.99756 7.41976 7.45717 9.0112 7.89035V13.8324C9.0112 16.3655 9.59505 17.9475 9.99997 17.9475ZM8.94529 4.49086C8.26727 4.49086 7.6646 3.8976 7.6646 3.20075C7.6646 2.51332 8.26727 1.92948 8.94529 1.92948C9.65155 1.92948 10.226 2.51332 10.226 3.20075C10.226 3.8976 9.65155 4.49086 8.94529 4.49086ZM9.99997 21.6578C15.9891 21.6578 19.5769 19.6426 19.5769 17.326C19.5769 14.7929 15.6972 13.1544 12.599 13.0225V14.7081C14.7084 14.8023 17.4299 15.7723 17.4299 17.1377C17.4299 18.7574 14.3035 19.878 9.99997 19.878C5.69647 19.878 2.57949 18.7668 2.57949 17.1377C2.57949 15.7723 5.30096 14.8023 7.40092 14.7081V13.0225C4.30278 13.1544 0.423035 14.7929 0.423035 17.326C0.423035 19.6426 4.01086 21.6578 9.99997 21.6578Z" fill="currentColor"/>
</svg>
)

const HeadPhonesSvg = () =>(
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 287.39 241.99" width={24} height={24}><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><g id="Capa_2-2" data-name="Capa 2"><g id="Capa_1-2-2" data-name="Capa 1-2"><path fill="currentColor" d="M62.74,132.63v98.42A10.61,10.61,0,0,0,84,231.7c0-.22,0-.43,0-.65V132.63a10.61,10.61,0,0,0-21.21,0Z"/><path fill="currentColor" d="M29.46,241.78H52.81V124.93a13.77,13.77,0,0,0,.16-1.7c0-50.41,40.69-91.42,90.7-91.42s90.69,41,90.69,91.42a15.73,15.73,0,0,0,.23,2.27V241.78h23.35c16.26,0,29.45-13.64,29.45-30.47V150.37a30.34,30.34,0,0,0-21.26-29.25C265,54.15,210.52,0,143.68,0S22.33,54.16,21.21,121.15A30.32,30.32,0,0,0,0,150.38v60.93C0,228.13,13.19,241.78,29.46,241.78Z"/><path fill="currentColor" d="M203.45,132.63v98.42a10.61,10.61,0,0,0,21.21.65c0-.22,0-.43,0-.65V132.63a10.61,10.61,0,0,0-21.21,0Z"/></g></g></g></g></svg>)

export const ArrowSidebarIcon = props => <Icon component={ArrowSidebar} {...props} />;
export const PowerOffIcon = props => <Icon component={PowerOff} {...props} />;
export const CategoriesIcon = props => <Icon component={CategoriesSvg} {...props} />;
export const UserIcon = props => <Icon component={UserSvg} {...props} />;
export const DocIcon = props => <Icon component={DocSvg} {...props} />;
export const SquareEditIcon = props => <Icon component={SquareSvg} {...props} />;
export const BookIconRed = props => <Icon component={BookSvg} {...props} />;
export const CircleCloseIcon = props => <Icon component={CircleCloseSvg} {...props} />;
export const BagIcon = props => <Icon component={BagSvg} {...props} />;
export const CrediCardIcon = props => <Icon component={CrediCardSvg} {...props} />;
export const SaveIcon = props => <Icon component={SaveSvg} {...props} />;
export const CircleAddIcon = props => <Icon component={CircleAddSvg} {...props} />;
export const EditMyInfoIcon = props => <Icon component={EditMyInfoSvg} {...props} />;
export const CoberturaIcon = props => <Icon component={CoberturaSvg} {...props} />;
export const HeadPhoneIcon = props => <Icon component={HeadPhonesSvg} {...props} />;