import React from "react";
import { CategoriesIcon, CoberturaIcon, CrediCardIcon, UserIcon } from "../components/customIcon";

import Coverage from "../routes/coverage";
import MyShop from "../routes/myshop";
// import Categories from "../routes/orders";
// import Order from "../routes/orders";
import Payments from "../routes/payments";

const Categories = React.lazy(() => import('../routes/orders'));


export const routesMap = [
    {
        path: "/orders",
        name: "Órdenes",
        icon: CategoriesIcon,
        View: Categories
    },
    {
        path: "/myshop",
        name: "Mi tienda",
        icon: UserIcon,
        View: MyShop
    },
    {
        path: "/coverage",
        name: "Cobertura",
        icon: CoberturaIcon,
        View: Coverage
    },
    {
        path: "/payments",
        name: "Pagos",
        icon: CrediCardIcon,
        View: Payments
    },
]