import React, { useContext, useEffect, useState } from 'react';
import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Button, Col, Form, Image, Input, InputNumber, message, Modal, Radio, Row, Select, Typography } from 'antd';
import { headerBearer, headerBearerFormData, postFormData, remove } from '../../tools/api';
import { baseUri, USER_DATA } from '../../tools/constants';
import { checkImage } from '../../tools/imageTool';
import removeItem from "../../assets/removeitem.svg";
import SelectCategories from './components/selectCategories';
import SelectSubcategories from './components/selectSubcategory';
import { getStorage } from '../../tools/storage';
import { ProductContext } from './products';

const {Title, Text} = Typography;
const {Option} = Select;

const optionsWithDisabled = [
    { label: 'Visible', value: true },
    { label: 'Oculto', value: false },
  ];

const ModalProduct = () => {
    const {visible, record, onSave, toggleModal, image, selectedImage, setImage, form, onFinish, categories, loading, subcategories,getSubcategories} = useContext(ProductContext);
    
    const [user, setUser] = useState(getStorage(USER_DATA))
    
    const [category, setCategory] = useState()
    
    const [visiblem, setVisiblem] = useState(false);

    useEffect(() => {
        initComponet()
    }, [record])

    const initComponet = async () => {
        if(record !== null) {
          console.log(record)
            await getSubcategories(record.idcategory);
            form.setFieldsValue(record)
            setImage({file: null, fileUri: `${baseUri}/public/products/${record.image}`});
        }
        console.log(record)
    }

    const toggleModalDelete = () => setVisiblem(!visiblem);

    const deleteItem = async () => {
        // try {
        //     setLoading(true)
        //     const result = await remove("/category/"+record.ID, headerBearer);
        //     if(result.success) {
        //         message.success(result.message);
        //         setLoading(false)
        //         setVisiblem(false);
        //         onSave()
        //         return
        //     } 
        //     throw result;
        // } catch (error) {
        //     message.error(error.message)
        //     setLoading(false)
        // }
    }

    // drago inf, minero, princesa, baby drago, mago elec, mago, horda de esb, tronco

    return (
      <Modal
        width={"50%"}
        visible={visible}
        closable={false}
        footer={[
          <Button
            onClick={() => {
              form.resetFields();
              setImage(null);
              toggleModal();
            }}
            className="custom_button_form_cancel"
            type="link"
            danger
            key="back"
          >
            Cancelar
          </Button>,
          <Button
            loading={loading}
            onClick={onFinish}
            className="custom_button_form"
            type="primary"
            key="create"
          >
            Crear
          </Button>,
          // record !== null ? (
          //   <Row>
          //     <Col>
          //       <Button
          //         onClick={toggleModalDelete}
          //         className="btn_delete_data"
          //         type="link"
          //         danger
          //       >
          //         Eliminar categoria <DeleteOutlined />
          //       </Button>
          //     </Col>
          //   </Row>
          // ) : null,
        ]}
      >
        <Row>
          <Col span={8}>
            <Typography.Title level={5}>Producto</Typography.Title>
            <div className="preview_image">
              {image !== null ? <Image src={image.fileUri} /> : null}
              <label className="label_image" for="upload-photo">
                <Input onChange={selectedImage} id="upload-photo" type="file" />
                <Typography.Text>
                  <PlusCircleFilled /> Foto de la categoría
                </Typography.Text>
              </label>
            </div>
          </Col>
          <Col span={16} style={{ paddingLeft: 8 }}>
            <Form layout="vertical" form={form} name="categories_form">
              {/* name */}
              <Form.Item
                name="name"
                label="Nombre del producto"
                rules={[
                  {
                    required: true,
                    message: "Debes llenar este campo",
                  },
                ]}
              >
                <Input className="custom_input" />
              </Form.Item>
              {/* select categorie */}
              <Form.Item rules={[
                  {
                    required: true,
                    message: "Debes llenar este campo",
                  },
                ]} name="idcategory" label="Categoria">
                <Select onChange={(value) => getSubcategories(value)}>
                  {categories.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              </Form.Item>
              {/* select subcategories */}
              <Form.Item rules={[
                  {
                    required: true,
                    message: "Debes llenar este campo",
                  },
                ]} name="idsubcategory" label="Subategoria">
                <Select >
                  {subcategories.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              </Form.Item>
              {/* description */}
              <Form.Item
                name="description"
                label="Descripcion"
                rules={[
                  {
                    required: true,
                    message: "Debes llenar este campo",
                  },
                ]}
              >
                <Input.TextArea className="custom_input" />
              </Form.Item>
              {/* Precio */}
              <Form.Item
                name="price"
                label="Precio del producto"
                rules={[
                  {
                    required: true,
                    message: "Debes llenar este campo",
                  },
                ]}
              >
                <InputNumber className="custom_input" />
              </Form.Item>
                            {/* Stock */}
                            <Form.Item
                name="stock"
                label="Cantidad de productos"
                rules={[
                  {
                    required: true,
                    message: "Debes llenar este campo",
                  },
                ]}
              >
                <InputNumber className="custom_input" />
              </Form.Item>

              {/* estado */}
              <Form.Item
                name="visible"
                label="Estado"
                rules={[
                  {
                    required: true,
                    message: "Debes llenar este campo",
                  },
                ]}
              >
                <Radio.Group
                  options={optionsWithDisabled}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        <Modal
          closable={false}
          visible={visiblem}
          onCancel={toggleModalDelete}
          footer={null}
        >
          <div className="modal_delete">
            <Title style={{ marginBottom: 20 }} level={4}>
              Eliminar categoría
            </Title>
            <img src={removeItem} alt="remove" />
            <Text style={{ marginTop: 20 }}>
              ¿Estas seguro que quieres eliminar esta subcategoria?
            </Text>
            <div style={{ marginTop: 20, width: "100%", textAlign: "center" }}>
              <Button
                className="custom_button_form_cancel"
                type="link"
                onClick={toggleModalDelete}
                danger
              >
                Cancelar
              </Button>
              <Button
                loading={loading}
                className="custom_button_form"
                type="primary"
                onClick={deleteItem}
              >
                Eliminar
              </Button>
            </div>
          </div>
        </Modal>
      </Modal>
    );
}



export default React.memo(ModalProduct);