import { Col, Typography } from 'antd';
import React from 'react';
import { baseUri } from '../../../tools/constants';

const {Text} = Typography;

export default function ProductCard({data, onClick}) {
    return (
      <Col xs={24} sm={12} md={8} lg={4}>
        <div style={{ paddingLeft: 10, width: "100%" }} onClick={onClick}>
          <div className="product_container">
            <img
              src={`${baseUri}/public/products/${data.image}`}
              alt={data.name}
            />
            <div className='overlay_products' />
            <div className="action_products">
              <Text className="text_action">{data.name.substring(1, 30)}...</Text>
            </div>
          </div>
        </div>
      </Col>
    );
}