import { Button, Image, Table, Typography } from 'antd';
import moment from 'moment';
import React, { createContext } from 'react';
import ActionTable from '../../components/actionTable';
import { DocIcon } from '../../components/customIcon';
import HeaderSection from '../../components/table/headerSection';
import Switch from '../../components/table/SwitchV2';
// import { baseUri } from '../../tools/constants';
// import ModalForm from './modalForm';
// import ModalFormTwo from './modalFormTwo';
import usePayments from './usePayments';

const {Text} = Typography;
const actionData = [{name: "Pendiente", value: 0}, {name: "Pagado", value: 4}]
const PaymentsContext = createContext()

function Payments() {
    const {
        search,
        hash,
        loading,
        data,
        routes,
        visible,
        record,
        visibleModal,
        image,
        form,
        status,
        onDeleteItem,
        onSearchFilter,
        toggleModal,
        onChangeHash,
        onViewDataVisble,
        onChagePage,
        onFinish,
        onSelectedImage,
        onToggleModalDelete,
        onClearImage
    } = usePayments(true);

    // const onSave = (opt, record) => {
    //     if(opt === 1) {
    //         const info = data;
    //         if (opt === 1) setData({...info, data: updateArray(record, data.data)});
    //     } else {
    //         fetchData();
    //     }
        
    //     toggleModal(null);
    // }

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'CreatedAt',
            key: 'CreatedAt',
            render: text => moment(text).format("DD/MM/YYYY")
        },
        {
            title: 'N° pedido',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => record.order.id
        },
        {
          title: 'Ctvo.',
          dataIndex: 'ctvo',
          key: 'ctvo',
        },
        {
          title: 'Costo pedido',
          dataIndex: 'total',
          key: 'total',
          render: (text, record) => record.order.total.toFixed(2)
        },
        {
          title: 'Comisión',
          dataIndex: 'fee',
          key: 'fee',
          render: (text, record) => record.order.fee_shop.toFixed(2)
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => record.order.paidAs === 1 ? <Text style={{color: "#219653"}}>Pagado</Text> : <Text style={{color: "#EB5757"}}>Pendiente</Text>
        },
        {
            title: 'Detalle',
            dataIndex: 'id',
            key: 'id',
            render: (text, records) => <Button style={{color: '#4F4F4F'}} onClick={() => toggleModal(records)} type='link' icon={<DocIcon />} />
        }
    ]

    return (
      <PaymentsContext.Provider value={{
        visibleModal,
        image,
        visible,
        loading,
        form,
        record,
        onDeleteItem,
        onFinish,
        onSelectedImage,
        onToggleModalDelete,
        onClearImage,
        toggleModal
      }}>
        <div>
          <HeaderSection showSearch title="Estado de los Pagos" onChange={(e) => onSearchFilter(e)} value={search} />
          <Switch onClick={onChangeHash} data={routes} />
          <ActionTable
            onChange={onViewDataVisble}
            onClick={() => toggleModal(null)}
            defaultActiveKey={status}
            data={actionData}
            title="Solicitar pago general"
            showButton
          />
          <Table
            style={{ marginTop: 20 }}
            size="small"
            columns={columns}
            rowKey={(row) => row.id}
            dataSource={data.rows}
            pagination={{
              showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} elementos`,
              total: data.total_rows,
              current: data.page,
              pageSize: data.limit,
              onChange: onChagePage,
            }}
            scroll={{ y: window.innerHeight * 0.4 }}
            loading={loading}
            // onChange={this.handleTableChange}
          />
          {/* {hash ? (
            <ModalForm 
            save={() => {}} 
            record={record} 
            image={image}
            onCancel={() => toggleModal(null)} />
          ) : (
            <ModalFormTwo save={() => {}} record={record} visible={visible} onCancel={() => toggleModal(null)} />
          )} */}
        </div>
      </PaymentsContext.Provider>
    );
}

export default Payments;
export {PaymentsContext}