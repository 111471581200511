import { Button, Input, message, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { headerBearer, post } from '../../../tools/api';
import { USER_DATA } from '../../../tools/constants';
import { getStorage, setStorage } from '../../../tools/storage';
import { useGoogleMaps } from 'react-hook-google-maps';
import { EditMyInfoIcon } from '../../../components/customIcon';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

const {Title, Text} = Typography;
const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
}
let marker;

export default React.memo(function EditTextMyShop() {
  const [user, setUser] = useState(getStorage(USER_DATA))
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [latlng, setLatlng] = useState({lat: 0, lng: 0})
  const [error, setError] = useState('');

  const {ref, map, google} = useGoogleMaps(
    "AIzaSyAlsV1OD39NpaSi7Nu-SpQFM3DzwdcaFL4",
    {
        center: {lat: 0, lng: 0},
        zoom: 3,
    }
);

useEffect(() => {
    console.log(user);
    if(map) {
        map.addListener('click', (e) => handleClickMap(e.latLng.lat(), e.latLng.lng()));
        if(user['lat'] === 0 && user['lng'] === 0) {
            if(navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(({coords}) => {
                    setLatlng({lat: coords.latitude, lng: coords.longitude});
                    addMarker(coords.latitude, coords.longitude);
                    map.moveCamera({center: new google.maps.LatLng(coords.latitude, coords.longitude),zoom: 16})
                    getAddress(coords.latitude, coords.longitude);

                }, err => {
                    message.error(err.message);
                }, options);
            } else {
                message.info("funcion no disponible para el navegador");
            }
        } else {

            setLatlng({lat: user['lat'], lng: user['lng']});
                    addMarker(user['lat'], user['lng']);
                    map.moveCamera({center: new google.maps.LatLng(user['lat'], user['lng']),zoom: 16})
                    setAddress(user['address']);
        }
    }
},[map, google]);

const handleClickMap = (lat, lng, flag = true) => {
    marker.setMap(null);
    addMarker(lat, lng);
    setLatlng({lat, lng});
    if (flag) getAddress(lat, lng);
}

const addMarker = (lat, lng) => {
    marker = new google.maps.Marker({position: { lat, lng}, map});
}

const getAddress = async (lat, lng) => {
    try {
        const geocoder = new google.maps.Geocoder();
        const response = await geocoder.geocode({latLng: new google.maps.LatLng(lat, lng)});
        setAddress(response.results[0].formatted_address);
    } catch (error) {
        message.error("Error al procesar coordenadas")
    }

}

const onChangeAddress = async (value) => {
    if (value !== address || value !== '') {
        setAddress(value);
            try {
                setError('');
                setLoading(true);
                if(!loading) {
                    const geocoder = new google.maps.Geocoder();
                    const response = await geocoder.geocode({address: value});
                    const coords = response.results[0].geometry.location;
                    // set data
                    setLatlng({lat: coords.lat(), lng: coords.lng()});
                    addMarker(coords.lat(), coords.lng());
                    map.moveCamera({center: coords,zoom: 16});
                    setLoading(false);
                }
                // setAddress(response.results[0].formatted_address);
            } catch (error) {
                if(error.code === 'ZERO_RESULTS') {
                    setError("Ubicación no encontrada");
                }
                setLoading(false)
                // message.error("Error al procesar coordenadas")
            }
    }
}

  const onCancel = () => setAddress(user['address'])

  const onSave = async () => {
      try {
          if(address === "" && latlng.lat === 0 && latlng.lng === 0) return message.error("Debes seleccionar una ubicación")
          setLoading(true);
          const response = await post("/business/"+user.id, {...latlng, address}, headerBearer)
          if(response.success) {
              setUser(response.data)
              setStorage(USER_DATA, response.data)
              setLoading(false)
              message.success(response.message)
              return
          }
          throw response;
          
      } catch (error) {
          console.log(error)
          setLoading(false)
          message.error(error.message)
      }
  }

  return (<div>
            <div className="edit_text_" style={{height: 120}}>
          <Title level={4}>Dirección del Negocio</Title>
          <div className="edit_my_shop_container">
            <EditMyInfoIcon />
            <Input
            onChange={(e) => onChangeAddress(e.target.value)}
            value={address}
              className="edit_my_shop"
              placeholder="Ejm: centro comercial A+b"
            />
          </div>
          {address !== user['address'] ? <div className='action_edit_text'>
              <Space>
              <Button 
              onClick={onSave} 
              loading={loading} style={{borderRadius: 8}} type='primary' size='small' icon={<SaveOutlined />}>Guardar</Button>
              <Button 
              size='small'
              onClick={onCancel} 
              style={{borderRadius: 8}} type='primary' danger icon={<CloseOutlined />}>Cancelar</Button>
              </Space>
          </div>: null}
          <Text type="danger">{error}</Text>
        </div>
        <div className='mapContainer_myshop'>
            <div ref={ref} className="map_" ></div>
            <div className={address !== user['address'] ? '' : 'overlay_map'} />
        </div>
        
    </div>);
})