import { Form, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../hooks/authContext";
import { get, headerBearerFormData, postFormData } from "../../tools/api";
import { addToArray, updateArray } from "../../tools/arrayTool";
import { USER_DATA } from "../../tools/constants";
import { checkImage } from "../../tools/imageTool";
import { getStorage } from "../../tools/storage";

export default function useProduct() {
    const {auth, token} = useAuth();
    // const [user, setUser] = useState(getStorage(USER_DATA))
    const [visible, setVisible] = useState(false);
    const [voffert, setVoffert] = useState(false);
    const [data, setData] = useState({rows: []});
    
    const [record, setRecord] = useState(null);
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [subcategories, setSubcategories] = useState([]);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchData()
        getCategories();
    },[])


    // modal products
    function toggleModal (values = null) {
        setVisible(!visible)
        if (values === null) return setRecord(values);
        if (values.id !== undefined) setRecord(values);
        // console
    }
    // modal offert
    function toggleModalOffert (values = null) {
        setVoffert(!visible);
        if (values.id !== undefined) setRecord(values);
        
    }

    const fetchData = async (page=1) => {
        try {
            setLoading(true)
            const response = await get(`/products/${auth.id}?page=${page}`);
            console.log(response);
            if(page > 1) {
                const result = response.rows.concat(data.rows);
                setData({...response, rows: result});
            } else {
                setData(response);
            }
            setLoading(false)
            // console.log(response)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    //* categories
    const getCategories = async () => {
        const response = await get("/category/all");
        setCategories(response.data);
    }
    //* subcategories
    const getSubcategories = async (id) => {
        const response = await get(`/subcategory?category=${id}`);
        setSubcategories(response.data);
    }

    const onSave = (opt, record) => {
        if(opt === 1) {
            if (opt === 1) setData({...data, rows: updateArray(record, data.rows)});
        } else {
            setData({...data, rows: addToArray(record, data.rows)})
        }
        
        toggleModal(null);
    }

        // preview image
    const selectedImage = (values) => {
        const file = values.target.files[0] // file
        const checki = checkImage(file);
        // check image
        if(!checki.success) return message.info(checki.error);

        let reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = function () {
            setImage({file, fileUri: reader.result});
        }
    }

    const onFinish = async () => {
        try {
            const values = await form.validateFields();
            if(record === null) {
                if(image === null) return message.info("Debes seleccionar una imagen");
                setLoading(true)
                values.name = values.name.toLowerCase();
                const response = await postFormData("/products", {...values, idbusiness: auth.id, image: image.file}, headerBearerFormData(token));
                if(response.success) {
                    // console.log(response)
                    form.resetFields()
                    setImage(null);
                    message.success(response.message)
                    setLoading(false);
                    onSave(2, response.data);
                    return;
                }
                throw response;
            } else {
                const response = await postFormData("/products/"+record.id, {...record, ...values, image: image.file !== null ? image.file : record.image} ,headerBearerFormData(token));
                if(response.success) {
                    console.log(response)
                    form.resetFields()
                    setImage(null);
                    message.success(response.message)
                    setLoading(false);
                    onSave(1, response.data);
                    return;
                }
                throw response;
            }
        } catch (error) {
            console.log(error)
            message.error(error.message)
            setLoading(false)
        }
        
    }

    return {
        data,
        visible,
        user: auth,
        record,
        onSave,
        toggleModal,
        image,
        form,
        fetchData,
        loading,
        selectedImage,
        setImage,
        onFinish,
        categories,
        subcategories,
        getSubcategories,
        voffert,
        toggleModalOffert
    }
}