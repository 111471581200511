import { Form, message } from "antd";
import moment from "moment";
import { useState } from "react";
import { headerBearer, post } from "../../tools/api";
import { USER_DATA } from "../../tools/constants";
import { getStorage, setStorage } from "../../tools/storage";
const format = 'h:mm a';

export default function useCoverage() {
    const [form] = Form.useForm();
    const [user, setUser] = useState(getStorage(USER_DATA));
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const openModal = () => {
        console.log(user)
        form.setFieldsValue({
            distance_max: user.distanceMax,
            free_distance: user.freeDistance,
            lv: [moment(user.openLV === '' ? 0 : user.openLV), moment(user.closeLV === '' ? 0 : user.closeLV)],
            sd: [moment(user.openSD === '' ? 0 : user.openSD), moment(user.closeSD === '' ? 0 : user.closeSD)],
        })
        setVisible(true);
    };
    const closeModal = () => {
        setVisible(false);
        form.resetFields();
    };

    const closeBusiness = async () => {
        try {
            setLoading(true);
            const values = await form.validateFields();
            console.log(values);
            const response = await post(`/business/${user.id}`, {open: !user.open}, headerBearer);
            console.log({response})
            if(response.success) {
                message.success(response.message);
                setStorage(USER_DATA, response.data);
                setUser(response.data);
                setLoading(false);
                return;
            }
            throw response;
            
        } catch (error) {
            message.error(error.message);
            setLoading(false);
            console.log(error);
        }
    }

    const onFinish = async () => {
        try {
            setLoading(true);
            const values = await form.validateFields();
            console.log(values);
            const response = await post(`/business/${user.id}`, {
                distanceMax: parseInt(values.distance_max),
                freeDistance: parseInt(values.free_distance),
                openLV: values.lv[0]._d,
                closeLV: values.lv[1]._d,
                openSD: values.sd[0]._d,
                closeSD: values.sd[1]._d,
            }, headerBearer);
            console.log({response})
            if(response.success) {
                message.success(response.message);
                setStorage(USER_DATA, response.data);
                setUser(response.data);
                setVisible(false);
                setLoading(false);
                form.resetFields();
                return;
            }
            throw response;
            
        } catch (error) {
            message.error(error.message);
            setLoading(false);
            console.log(error);
        }
    }

    return {
        user,
        visible,
        openModal,
        closeModal,
        form,
        onFinish,
        loading,
        closeBusiness
    }
}