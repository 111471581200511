import { Button, Tabs, Typography } from 'antd';
import React from 'react';

const { TabPane } = Tabs;

const ActionTable = ({showButton, title, onClick, onChange, defaultActiveKey, data}) => {
    return(
        <div className='action_table_node'>
            <div className='action_status'>
                <Typography.Title level={5} style={{marginRight: 20}}>Estados: </Typography.Title>
                <Tabs defaultActiveKey={defaultActiveKey} onChange={(e) => onChange(e)}>
                    {data.map(item => <TabPane tab={item.name} key={item.value} />)}
                </Tabs>
            </div>
            {showButton && <Button onClick={onClick} className='custom_button' style={{maxWidth: '40%', minWidth: '20%'}} type='primary'>{title}</Button>}
        </div>
    );
}

export default ActionTable;