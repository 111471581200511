import { Form, message } from "antd";
import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/authContext";
import { get, headerBearer, post } from "../../tools/api";
import { USER_DATA } from "../../tools/constants";
import { getStorage, setStorage } from "../../tools/storage";

export default function useProfile() {
    const [form] = Form.useForm(); // form
    const [formbank] = Form.useForm(); // form
    const {auth, token, setAuth} = useAuth();

    const [bank, setBank] = useState(null);
    const [loading, setLoading] = useState(false); // loader

    useEffect(() => {
        form.setFieldsValue(auth)
        fetchData();
    },[]);

    const fetchData = async () => {
        try {
            const response = await get(`/bank?user=2&id=${auth.id}`, headerBearer(token));
            if(!response.success) throw response;
            if(response.data.length > 0) {
                setBank(response.data[0]);
                formbank.setFieldsValue(response.data[0]);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const saveData = async () => {
        try {
            const values = await form.validateFields();
            setLoading(true);
            const response = await post(`/business/${auth.id}`, values, headerBearer(token));
            if(!response.success) throw response;
            message.success(response.message);
            console.log(response);
            setAuth(response.data);
            setStorage(USER_DATA, response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error(error.message);
        }
    }

    const onFinish = async () => {
        try {
            const values = await formbank.validateFields();
            setLoading(true);
            let response;
            if(bank === null) {
                response = await post(`/bank`, {...values, iduser: auth.id, type_user: 2}, headerBearer(token));
            } else {
                response = await post(`/bank/${bank.id}`, {...bank, ...values}, headerBearer(token));
            }

            if(!response.success) throw response;
            message.success(response.message);
            console.log(response);
            setBank(response.data);
            formbank.setFieldsValue(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error(error.message);
        }
      }

    return {
        form,
        formbank,
        bank,
        auth,
        loading,
        saveData,
        onFinish
    }
}