import { message } from 'antd';
import { useEffect, useState } from 'react';
import { headerBearerFormData } from '../tools/api';
import { baseUri, prefixUri, USER_DATA } from '../tools/constants';
import { checkImage } from '../tools/imageTool';
import { getStorage, setStorage } from '../tools/storage';
import { useAuth } from './authContext';

function useUploadImageWithProgress({path, uriImg, valueData}) {
    const {token} = useAuth();
    const [isUpload, setIsUpload] = useState(false)
    const [progress, setProgress] = useState(0)
    const [user, setUser] = useState(getStorage(USER_DATA))
    const [image, setImage] = useState({file: null, uri: null});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setImage({file: null, uri: user[valueData] !== "" ? baseUri+uriImg+user[valueData] : null})
    },[])

    const showImage = (file) => {
        const checki = checkImage(file);
        if(!checki.success) return message.info(checki.error);
        setLoading(true)
        let reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = function() {
            setImage({file, uri: reader.result});
        }
        setLoading(false)
        // console.log(previewImage(file))
    }

    const onImageCancel = () => {
        setImage({file: null, uri: user ? baseUri+uriImg+user[valueData] : null})
      }


    const saveImage = (file = null) => {
        console.log(file)
        try {
            setIsUpload(true)
            let data = new FormData();
            data.append(valueData, file);
    
            let request = new XMLHttpRequest();
            
            request.open('POST', baseUri+prefixUri+path+user.id); 
            request.setRequestHeader("Authorization", headerBearerFormData(token).Authorization);
            
            // upload progress event
            request.upload.addEventListener('progress', function(e) {
              // upload progress as percentage
              
              let percent_completed = (e.loaded / e.total)*100;
              setProgress(percent_completed)
            //   console.log(percent_completed);
            });
    
            // request finished event
            request.addEventListener('load', function(e) {
              // HTTP status message (200, 404 etc)
            //   console.log(request.status);
              setIsUpload(false)
              request.removeEventListener("progress", null)
              request.removeEventListener("load", null)
              const response = JSON.parse(request.response)
              if (response.success) {
                setUser(response.data)
                setStorage(USER_DATA, response.data)
                message.success(response.message);
                setImage({file: null, uri: baseUri+uriImg+response.data[valueData]})
              } else {
                throw response;
              }
              
              // request.response holds response from the server
              console.log();
            });
    
            // send POST request to server
            request.send(data);
    
          } catch (error) {
            message.error(error.message)
            setIsUpload(false)
            console.log(error)
          }
    }


    return {progress, isUpload, user, image, saveImage, showImage, onImageCancel};
    
}

export default useUploadImageWithProgress;