import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Input, message, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditMyInfoIcon } from '../../../components/customIcon';
import { useAuth } from '../../../hooks/authContext';
import { headerBearer, post } from '../../../tools/api';
import { TOKEN, USER_DATA } from '../../../tools/constants';
import Cookies from '../../../tools/cookies';
import { getStorage, setStorage } from '../../../tools/storage';

const {Title} = Typography;
const cookies = new Cookies();

export default function EditTextMyShop({field, title, placeholder}) {
    const {token, setAuth} = useAuth();
    // console.log(auth);
    const [user, setUser] = useState(getStorage(USER_DATA))
    const [value, setValue] = useState(user[field]);
    const [loading, setLoading] = useState(false);

    const onCancel = () => setValue(user[field])

    const onSave = async () => {
        try {
            if(value === "") return message.error("Nombre no puede estar vacio")
            setLoading(true);
            const response = await post("/business/"+user.id, {[field]: value}, headerBearer(token))
            if(response.success) {
                setAuth(response.data)
                setUser(response.data);
                setStorage(USER_DATA, response.data)
                setLoading(false)
                message.success(response.message)
                return
            }
            throw response;
            
        } catch (error) {
            console.log(error)
            setLoading(false)
            message.error(error.message)
        }
    }

    return (
      <div>
        <div className="edit_text_">
          <Title level={4}>{title}</Title>
          <div className="edit_my_shop_container">
            <EditMyInfoIcon />
            <Input
            onChange={(e) => setValue(e.target.value)}
            value={value}
              className="edit_my_shop"
              placeholder={placeholder}
            />
          </div>
          {value !== user[field] ? <div className='action_edit_text'>
              <Space>
              <Button onClick={onSave} loading={loading} style={{borderRadius: 8}} type='primary' icon={<SaveOutlined />}>Guardar</Button>
              <Button onClick={onCancel} style={{borderRadius: 8}} type='primary' danger icon={<CloseOutlined />}>Cancelar</Button>
              </Space>
          </div>: null}
        </div>
      </div>
    );
}