import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, message, Progress, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { CircleAddIcon } from '../../../components/customIcon';
import useUploadImageWithProgress from '../../../hooks/useUploadImageWithProgress';
// import useUser from '../../../hooks/useUser';
import { baseUri, prefixUri, USER_DATA } from '../../../tools/constants';
import { checkImage } from '../../../tools/imageTool';
import { getStorage } from '../../../tools/storage';

const {Title, Text} = Typography;

const initialValues = {
  path: "/business/",
  valueData: "banner_p",
  uriImg: "/public/business/"
}

export default function PromotionBanner () {
    const {
      progress, 
      isUpload, 
      user, 
      image, 
      showImage, 
      saveImage, 
      onImageCancel
    } = useUploadImageWithProgress(initialValues)

    if (user === null) return <p>Loading</p>;

    return (
      <div className='banner_container'>
        
          {image.uri === null && user.banner_p === "" ? (
            <label htmlFor="bannerpromotion">
            <div className="upload_banner_empty">
              <CircleAddIcon />
              <Text style={{ paddingLeft: 20 }}>
                Coloca una imagen de banner de tu negocio
              </Text>
            </div>
            </label>
          ) : (
            <div className="image_banner_container">
              <img
                className="image_banner_preview"
                src={image.uri}
                alt="mandaitos banner"
              />
    {(image.file === null && image.uri !== null) ? <div className='image_actions_b'>
                    <Space>
                    <Button style={{color: "#fff"}} size='small' type='link' icon={<EyeOutlined />}>Ver</Button>
                    <span style={{color: "#fff"}}>|</span>
                    <Button style={{color: "#fff"}} size='small' type='link' danger icon={<EditOutlined />}><label htmlFor="bannerpromotion">Editar</label></Button>
                    </Space>
                </div>: null}
            </div>
          )}
        
        {image.file !== null ? <div className='banner_actions'>
                  <Space>
                  <Button onClick={() => saveImage(image.file)} size='small' type='primary'>Guardar</Button>
                  <Button onClick={onImageCancel} size='small' type='primary' danger>Cancelar</Button>
                  </Space>
              </div>: null}
        <input
          className="inputfile"
          onChange={(e) => showImage(e.target.files[0])}
          id="bannerpromotion"
          type="file"
        />
        {isUpload ? <div className='overlay_banner'>
            <Progress style={{width: "60%"}} strokeColor="#FA8B20" percent={progress} showInfo={false} />
            <Text>Subiendo</Text>
        </div> : null}

      </div>
    );
}