import React from 'react';
import { Typography } from 'antd';
import NotificationCard from '../components/notification_card';
import useNotification from '../hooks/useNotification';

const {Title} = Typography;

const Notifications = () => {
    const {loading, data} = useNotification();
    return (
        <div className='notifications_container'>
            <Title level={3}>Notificaciones Mandaitos</Title>
            <div className='scrolled_notifications'>
                {data.map(item => <NotificationCard />)}
                
            </div>
        </div>
    );
}

export default Notifications;