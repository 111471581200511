import { Form, Radio, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { get } from '../../../tools/api';

const {Option} = Select;

const SelectSubcategories = ({category = 0}) => {
    const [value, setValue] = useState(1);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        initComponent()
    },[category])

    const initComponent = async () => {
      if (category > 0) {
        const response = await get("/subcategory?category="+category);
        if(response.data.length > 0) {
          setCategories(response.data)
          setValue(response.data[0].id)
          console.log(response)
        }

      }
    }

    

    return (
        <Form.Item name="idsubcategory" label="Selecciona una categoria">
                    <Select
        onChange={(e) => setValue(e)}
        className="custom_select"
      >
        {/* <Select.Option className="option_" value={1}>1 <Radio checked={category === 1} /></Select.Option> */}
        {categories.map((item) => (
          <Option key={item.id} value={item.id}>
            {item.name} <Radio checked={value === item.id} />
          </Option>
        ))}
      </Select>
        </Form.Item>
    );
}

export default SelectSubcategories;